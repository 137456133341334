<template>
  <div class="container container-xl">
    <h1 v-html="$t('processing_form_read_title')"></h1>
    <div class=""></div>

    <div class="row search">
      <div class="col-xs-6 col-md-4">
        <!-- Milchverwerter -->
        <h2 v-html="$t('processing_form_read_search_title_dairy')"></h2>
        <!-- MBH-Ident -->
        <v-text-field
          name="dairyIdent"
          :label="$t('processing_form_read_search_dairy_ident')"
          v-model="searchCard.dairyIdent"
          @keyup.enter="search"
        ></v-text-field>
        <p class="align-right link" @click="toggleMBHFilter" color="primary">
          {{ this.showMBHFilter ? $t('less') : $t('more') }}
        </p>
        <!-- Status -->
        <v-select
          :label="$t('processing_form_read_search_dairy_status')"
          :items="dairyOpenOptions"
          v-model="searchCard.dairyActive"
          item-value="value"
          item-title="text"
          clearable
          @keyup.enter="search"
          v-if="showMBHFilter"
        />
      </div>
      <div class="col-xs-6 col-md-4">
        <!-- Gesuch -->
        <h2 v-html="$t('processing_form_read_title')"></h2>
        <!-- Gesuchs-ID -->
        <v-text-field name="ident" :label="$t('processing_form_read_search_ident')" v-model="searchCard.ident" @keyup.enter="search"></v-text-field>
        <!-- Status -->
        <v-select
          :label="$t('processing_form_read_search_dairy_status')"
          :items="formStatus"
          v-model="searchCard.statusIdent"
          item-value="ident"
          :item-title="this.$getLangKey()"
          clearable
          @keyup.enter="search"
        />
        <!-- Periode -->
        <DbmMonthPicker
          id="search_segmentation_forms_completon_form"
          v-model="searchCard.yearMonthUntil"
          :dateLabel="$t('processing_form_read_search_year_month_until')"
          :clearable="true"
        />
        <!-- Periode -->
        <v-select
          :label="$t('processing_form_read_search_dairy_correction')"
          :items="correctionOptions"
          v-model="searchCard.correction"
          item-value="value"
          item-title="text"
          clearable
          @keyup.enter="search"
        />
        <p class="align-right link" @click="toggleFilter" color="primary">
          {{ this.showFilter ? $t('less') : $t('more') }}
        </p>

        <p></p>
        <div v-if="showFilter">
          <!-- Periodizität -->
          <v-select
            id="formPeriod"
            required
            :items="formPeriods"
            :label="$t('processing_form_template_create_formPeriod')"
            :hint="$t('processing_form_template_create_formPeriod_description')"
            persistent-hint
            item-value="id"
            :item-title="this.$getLangKey()"
            clearable
            v-model="searchCard.formPeriod"
            @keyup.enter="search"
          />
          <!-- Korrespondenzweg -->
          <v-select
            id="reportingObligation"
            required
            :items="reportingObligations"
            :label="$t('processing_form_reporting_obligation')"
            item-value="name"
            clearable
            :item-title="this.$getLangKey()"
            v-model="searchCard.reportingObligation"
            @keyup.enter="search"
          />
          <!-- Ablage-NR -->
          <v-text-field name="code" :label="$t('processing_form_read_table_code')" type="text" v-model="searchCard.code" @keyup.enter="search"></v-text-field>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <v-btn @click="search" color="primary" class="button-margin"><span v-html="$t('processing_form_read_search')" /></v-btn>
      <v-btn @click="reset" color="secondary" class="button-margin"><span v-html="$t('processing_form_read_reset')" /></v-btn>
    </div>
    <div>
      <v-data-table-server
        :headers="fields"
        :loading="loading"
        :items="items"
        :fields="fields"
        density="compact"
        :items-length="totalElements"
        :multi-sort="true"
        @update:itemsPerPage="routerPushSize"
        @update:page="routerPushPage"
        @update:sort-by="routerPushDTSort"
        :sort-by="toDTSort(query.sort)"
        :page="getDTPageFromRoute(query.page)"
        :items-per-page="query.size"
        :items-per-page-options="$itemsPerPageOptions"
      >
        <template v-slot:[`item.edit`]="{ item }">
          <template
            v-if="
              $privileges.has({
                path: '/processingForms',
                permission: 'write'
              }) ||
              $privileges.has({
                path: '/processingForms',
                permission: 'acceptErrors'
              })
            "
          >
            <router-link :to="{ name: 'processingForms_edit', params: { id: item.id } }" class="tableaction link">
              <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon></router-link
            >
          </template>
        </template>
        <template v-slot:[`item.dairyIdent`]="{ item }">
          <template v-if="$privileges.has({ path: '/dairies', permission: 'write' })">
            <router-link
              :to="{
                name: 'masterdata_dairies_edit',
                params: { id: item.dairyId, tab: 'overview' }
              }"
              class="tableaction link"
            >
              {{ item.dairyIdent }}
            </router-link>
          </template>
          <template v-else>
            {{ item.dairyIdent }}
          </template>
        </template>
        <template v-slot:[`item.statusClosedAndNoValues`]="{ item }">
          <template v-if="item.statusClosedAndNoValues"> X </template>
        </template>
        <template v-slot:[`item.responsibilityGroup`]="{ item }">
          <template v-if="item.responsibilityGroup">
            {{ translateResponsabilityGroup(item.responsibilityGroup) }}
          </template>
        </template>
        <template v-slot:[`item.correction`]="{ item }">
          {{ item.correction === true ? $t('processing_form_correction_yes') : $t('processing_form_correction_no') }}
        </template>
        <template v-slot:[`item.statusIdent`]="{ item }">
          {{ item['status' + this.$getUpLangKey()] }}
        </template>
        <template v-slot:[`item.validFrom`]="{ item }">{{ $moment(item.validFrom).format('DD.MM.YYYY') }}</template>
        <template v-slot:[`item.validUntil`]="{ item }">{{ $moment(item.validUntil).format('DD.MM.YYYY') }}</template>
      </v-data-table-server>
    </div>
  </div>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import _ from 'lodash'
import { showError } from '@/services/axios'

import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import { DTSHeader } from '@/services/BackendService'
import { useFormPeriodsStore } from '@/store/enums/FormPeriodsStore'
import { useFormStatusStore } from '@/store/enums/FormStatusStore'
import { useReportingObligationsStore } from '@/store/enums/ReportingObligationsStore'
import { useResponsibilityGroupsStore } from '@/store/enums/ResponsibilityGroupsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'processingForms_read',
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      menu1: false,
      searchCard: {
        dairyIdent: this.$route.query?.dairyIdent ? this.$route.query.dairyIdent : null,
        dairyActive: this.$route.query?.dairyActive ? this.$route.query.dairyActive : null,
        ident: this.$route.query?.ident ? this.$route.query.ident : null,
        dairyOpen: this.$route.query?.dairyOpen ? this.$route.query.dairyOpen : null,
        yearMonthUntil: this.$route.query?.yearMonthUntil ? this.$route.query.yearMonthUntil : null,
        correction: this.$route.query?.correction ? this.$route.query.correction : null,
        formPeriod: this.$route.query?.formPeriod ? this.$route.query.formPeriod : null,
        reportingObligation: this.$route.query?.reportingObligation ? this.$route.query.reportingObligation : null,
        code: this.$route.query?.code ? this.$route.query.code : null
      },
      totalElements: 0,
      loading: false,
      showFilter: false,
      showMBHFilter: false,
      items: [],
      dairyOpenOptions: [
        { text: this.$t('search_dairy_active'), value: true },
        { text: this.$t('search_dairy_not_active'), value: false }
      ],
      correctionOptions: [
        { text: this.$t('processing_form_correction_yes'), value: true },
        { text: this.$t('processing_form_correction_no'), value: false }
      ],
      fields: <DTSHeader[]>[
        {
          key: 'edit',
          sortable: false,
          width: '2%'
        },
        //Verwerter-ID
        {
          title: this.$t('processing_form_table_header_dairy_ident'),
          key: 'dairyIdent',
          sortable: true,
          align: 'left'
        },
        //Name/Firma
        {
          title: this.$t('processing_form_table_header_name1'),
          key: 'dairyPresentManagerName1',
          sortable: true
        },
        //Vorname/Zusatz
        {
          title: this.$t('processing_form_table_header_name2'),
          key: 'dairyPresentManagerName2',
          sortable: true
        },
        //Status
        {
          title: this.$t('processing_form_read_search_dairy_status'),
          key: 'statusIdent',
          sortable: true
        },
        //Periodizität
        {
          title: this.$t('processing_form_read_search_periodicity'),
          key: 'formPeriod' + this.$getUpLangKey(),
          sortable: true
        },
        // Validierung
        {
          value: 'statusClosedAndNoValues',
          sortable: true,
          size: '2%'
        },
        //Von
        {
          title: this.$t('processing_form_table_header_valid_from'),
          key: 'validFrom',
          sortable: true
        },
        //Bis
        {
          title: this.$t('processing_form_table_header_valid_until'),
          key: 'validUntil',
          sortable: true
        },
        //Korrektur
        {
          title: this.$t('processing_form_table_header_correction'),
          key: 'correction',
          sortable: true
        },
        //Zuständig
        {
          title: this.$t('processing_form_table_header_responsible_employee'),
          key: 'responsibilityGroup',
          sortable: false
        },
        //Gesuchs-ID
        {
          title: this.$t('processing_form_table_header_ident'),
          key: 'ident',
          align: 'left',
          sortable: true
        },
        //Ablage-Nr.
        {
          title: this.$t('processing_form_read_table_code'),
          key: 'code',
          sortable: true
        }
      ]
      //productGroups: [],
      //selected: true,
      //term: { nameDe: '' },
      //model: defaultData,
    }
  },
  components: {
    DbmMonthPicker: DbmMonthPicker
  },
  computed: {
    responsibilityGroups() {
      return useResponsibilityGroupsStore().responsibilityGroups
    },
    query() {
      return {
        ...this.searchCard,
        ...this.getJavaPageOptions({ sort: ['statusIdent,asc', 'validUntil,asc'] })
      }
    },
    formStatus() {
      return useFormStatusStore().formStatus
    },
    formPeriods() {
      return useFormPeriodsStore().formPeriods
    },
    reportingObligations() {
      return useReportingObligationsStore().reportingObligations
    }
  },
  methods: {
    search() {
      this.$router
        .push({
          name: 'processingForms_read',
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load(this.query)
        })
    },
    reset() {
      this.searchCard = {
        dairyIdent: null,
        dairyActive: null,
        ident: null,
        dairyOpen: null,
        yearMonthUntil: null,
        correction: null,
        formPeriod: null,
        reportingObligation: null,
        code: null
      }
    },
    async load() {
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/processingForms/find', { params: this.query })
        const items = response.data.content
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    toggleFilter() {
      this.showFilter = !this.showFilter
    },
    toggleMBHFilter() {
      this.showMBHFilter = !this.showMBHFilter
    },
    show(data: any) {
      return data.value
    },
    openModal() {
      this.$refs.myModal.show()
    },
    translateResponsabilityGroup(rg: any) {
      let groups = this.responsibilityGroups.filter((el: any) => el.name == rg)
      return groups[0][this.$getLangKey()]
    }
  },

  watch: {
    '$route.query': {
      handler() {
        this.load()
      },
      deep: true
    }
  },
  beforeMount() {
    useFormPeriodsStore().fetchFormPeriods()
    useFormStatusStore().fetchFormStatus()
    useReportingObligationsStore().fetchReportingObligations()
    useResponsibilityGroupsStore().fetchResponsibilityGroups()
  },
  mounted() {
    this.initialized = true
    if (this.$route.query.size) this.load(this.$route.query)
  }
})
</script>
